import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from './layout/Spinner';
import ScrollToTop from './layout/ScrollToTop';

const Home = lazy(() => import('./home/Home'));
const Article = lazy(() => import('./article/Article'));
const Series = lazy(() => import('./series/Series'));
const About = lazy(() => import('./about/About'));
const Error404 = lazy(() => import('./errors/Error404'));
const SecureApp = lazy(() => import('./app/SecureApp'));

class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={ Home } />
          <Route path="/about" component={ About } />
          <Route path="/article/:articleId" component={ Article } />
          <Route path="/errors/404" component={ Error404 } />
          <Route path="/series" component={ Series } />
          <Route path="/app" component={ SecureApp } />
          <Redirect to="/" component={ Home } />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;