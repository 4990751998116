import {
    LIST_SERIES_BY_AUTHOR,
    LIST_SERIES_BY_AUTHOR_RESULT
} from '../actions';


const INIT_STATE = {
    isLoadingSeriesByAuthor: false,
    seriesByAuthor: [],
    seriesByAuthorNextToken: null
};


const fn = (state = INIT_STATE, action) => {
	switch (action.type) {
        case LIST_SERIES_BY_AUTHOR:
            return { ...state, isLoadingSeriesByAuthor:true };
        case LIST_SERIES_BY_AUTHOR_RESULT:
            return { ...state, isLoadingSeriesByAuthor: false, seriesByAuthor: action.payload.series.items, seriesByAuthorNextToken: action.payload.series.nextToken };
        default: 
            return { ...state };
    }
}


export default fn;