/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listUsers = /* GraphQL */ `
  query ListUsers(
    $userId: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        firstName
        lastName
        emailAddress
        biography
        interests
        technologies
        socialLinks {
          github
          linkedin
          website
        }
        profilePicture {
          bucket
          region
          key
          level
          identity_pool_id
        }
        isActive
        inactiveDate
        createdAt
        updatedAt
        series {
          nextToken
        }
        articles {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($userId: String!) {
    getUser(userId: $userId) {
      userId
      firstName
      lastName
      biography
      interests
      technologies
      socialLinks {
        github
        linkedin
        website
      }
      profilePicture {
        bucket
        region
        key
        level
        identity_pool_id
      }
      series {
        items {
          serieId
          title
          authorId
          description
          articleOrder
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      recentProjects {
        name
        description
        link
      }
      articles {
        items {
          articleId
          createdTimestamp
          authorId
          title
          podcast {
            bucket
            region
            key
            level
            identity_pool_id
          }
          tag
          thumbnail {
            bucket
            region
            key
            level
            identity_pool_id
          }
          body {
            bucket
            region
            key
            level
            identity_pool_id
          }
          refId
          serieId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listBlogSeries = /* GraphQL */ `
  query ListBlogSeries(
    $serieId: String
    $filter: ModelBlogSerieFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBlogSeries(
      serieId: $serieId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        serieId
        title
        authorId
        description
        articleOrder
        createdAt
        updatedAt
        author {
          userId
          firstName
          lastName
          biography
          interests
          technologies
        }
        owner
        articles {
          items {
            articleId
            createdTimestamp
            authorId
            isLocked
            isNotFree
            title
            podcast {
              bucket
              region
              key
              level
              identity_pool_id
            }
            tag
            thumbnail {
              bucket
              region
              key
              level
              identity_pool_id
            }
            body {
              bucket
              region
              key
              level
              identity_pool_id
            }
            refId
            serieId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBlogSerie = /* GraphQL */ `
  query GetBlogSerie($serieId: String!) {
    getBlogSerie(serieId: $serieId) {
      serieId
      title
      authorId
      description
      articleOrder
      createdAt
      updatedAt
      author {
        userId
        firstName
        lastName
        biography
        interests
        technologies
        socialLinks {
          github
          linkedin
          website
        }
        profilePicture {
          bucket
          region
          key
          level
          identity_pool_id
        }
      }
      owner
      articles {
        items {
          articleId
          createdTimestamp
          authorId
          title
          podcast {
            bucket
            region
            key
            level
            identity_pool_id
          }
          tag
          thumbnail {
            bucket
            region
            key
            level
            identity_pool_id
          }
          body {
            bucket
            region
            key
            level
            identity_pool_id
          }
          refId
          isLocked
          isNotFree
          serieId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const seriesByAuthor = /* GraphQL */ `
  query SeriesByAuthor(
    $authorId: String
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogSerieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seriesByAuthor(
      authorId: $authorId
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        serieId
        title
        authorId
        description
        articleOrder
        createdAt
        updatedAt
        author {
          userId
          firstName
          lastName
          biography
          interests
          technologies
        }
        owner
        articles {
          items {
            articleId
            createdTimestamp
            authorId
            title
            podcast {
              bucket
              region
              key
              level
              identity_pool_id
            }
            tag
            thumbnail {
              bucket
              region
              key
              level
              identity_pool_id
            }
            body {
              bucket
              region
              key
              level
              identity_pool_id
            }
            refId
            isLocked
            isNotFree
            serieId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getArticle = /* GraphQL */ `
  query GetArticle($articleId: ID!) {
    getArticle(articleId: $articleId) {
      articleId
      createdTimestamp
      authorId
      title
      isLocked
      isNotFree
      podcast {
        bucket
        region
        key
        level
        identity_pool_id
      }
      tag
      thumbnail {
        bucket
        region
        key
        level
        identity_pool_id
      }
      body {
        bucket
        region
        key
        level
        identity_pool_id
      }
      refId
      serieId
      githubResources {
        name
        link
      }
      externalResources {
        name
        link
      }
      createdAt
      updatedAt
      author {
        userId
        firstName
        lastName
      }
      serie {
        serieId
        title
        authorId
        description
        articleOrder
        owner
        articles {
          items {
            articleId
            title
            refId
            createdTimestamp
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $articleId: ID
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listArticles(
      articleId: $articleId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        articleId
        createdTimestamp
        authorId
        title
        podcast {
          bucket
          region
          key
          level
          identity_pool_id
        }
        tag
        thumbnail {
          bucket
          region
          key
          level
          identity_pool_id
        }
        body {
          bucket
          region
          key
          level
          identity_pool_id
        }
        refId
        serieId
        isLocked
        isNotFree
        createdAt
        updatedAt
        author {
          userId
          firstName
          lastName
          emailAddress
          biography
          interests
          technologies
          isActive
          inactiveDate
          createdAt
          updatedAt
        }
        serie {
          serieId
          title
          authorId
          description
          articleOrder
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const articlesByAuthor = /* GraphQL */ `
  query ArticlesByAuthor(
    $authorId: String
    $createdTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesByAuthor(
      authorId: $authorId
      createdTimestamp: $createdTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        articleId
        createdTimestamp
        authorId
        title
        podcast {
          bucket
          region
          key
          level
          identity_pool_id
        }
        tag
        thumbnail {
          bucket
          region
          key
          level
          identity_pool_id
        }
        body {
          bucket
          region
          key
          level
          identity_pool_id
        }
        refId
        isLocked
        isNotFree
        serieId
        createdAt
        updatedAt
        serie {
          serieId
          title
          authorId
          description
          articleOrder
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const articlesBySerie = /* GraphQL */ `
  query ArticlesBySerie(
    $serieId: String
    $articleId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesBySerie(
      serieId: $serieId
      articleId: $articleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        articleId
        createdTimestamp
        authorId
        title
        podcast {
          bucket
          region
          key
          level
          identity_pool_id
        }
        tag
        thumbnail {
          bucket
          region
          key
          level
          identity_pool_id
        }
        body {
          bucket
          region
          key
          level
          identity_pool_id
        }
        refId
        serieId
        createdAt
        updatedAt
        author {
          userId
          firstName
          lastName
          biography
          interests
          technologies
        }
        serie {
          serieId
          title
          authorId
          description
          articleOrder
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
