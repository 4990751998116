import React from 'react'

function Footer() {

    return (
        <footer className="footer bg-dark-primary">
            <div className="container-fluid">
                <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
                    <span className="text-white text-center text-sm-right d-block d-sm-inline-block w-100">© 2021 Freeldom. All Rights Reserved.</span>
                </div>
            </div>
        </footer>
    )
}

export default Footer;