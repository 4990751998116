import { combineReducers } from 'redux';
import settings from './settings/reducer';
import blogger from './blogger/reducer';
import article from './article/reducer';
import auth from './auth/reducer';
import serie from './serie/reducer';

// Import all the reducer.js files from subfolder and combine them

const reducers = combineReducers({
    settings,
    blogger,
    article,
    auth,
    serie
});

export default reducers;