import {
    FETCH_BLOGGER,
    FETCH_BLOGGER_SUCCESS
} from '../actions';


const INIT_STATE = {
    firstName: 'Matthieu',
    lastName: 'Blais',
    picture: 'https://sfo2.digitaloceanspaces.com/advocu/gde/profile-photos/2019/10/30/10fe8c6b4edaa12392b8.jpg',
    technologies: ['Machine Learning', 'Cloud Architecture', 'DevOps'],
    biography: 'Mitsuhisa Ohta received his Ph.D. in Physics from Kyoto University in 2010. In the same year, he joined BrainPad Inc., a machine learning company in Japan, and has developed a natural language processing (NLP) engine, a new recommendation algorithm, and some other machine learning products. Now, he is engaged in researching new technologies, including deep learning, and providing technical advice to data scientists and engineers. Furthermore, he is an organizer of the TensorFlow User Group in Japan.',
    interests: ['Sport', 'Marathoner', 'Teaching'],
    social: {
        github: 'GitHub',
        linkedin: 'LinkedIn',
    },
    isLoadingBlogger: true,
    profile: {}
};


const fn = (state = INIT_STATE, action) => {
	switch (action.type) {
        case FETCH_BLOGGER:
            return { ...state, isLoadingBlogger: true, profile: {} };
        case FETCH_BLOGGER_SUCCESS:
            return { ...state, isLoadingBlogger: false, profile: action.payload.blogger, success: Object.keys(action.payload.blogger).length > 0 };
        default: 
            return { ...state };
    }
}

export default fn;