/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:e202ff37-dcaa-4c8d-a0aa-62c1129f8e0d",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_G6lFtAjwm",
    "aws_user_pools_web_client_id": "7f01laijm52mnr4r202m0a74if",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://isvs3dyvkbfuhiqzfj7pakol3q.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-qfdykldcozdblllkcaez2p6doy",
    "aws_user_files_s3_bucket": "amplify-website-usercontent-askdsdawe1293235049-main",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
