import {
    IS_AUTHENTICATED,
    IS_AUTHENTICATED_RESULT,
    SIGNED_IN,
    SIGNED_IN_SUCCESS,
    SIGNED_OUT
} from '../actions';


const INIT_STATE = {
    isCheckingAuth: false,
    isAuth: false,
    authUser: null,
    hasCheckedAuth: false,
    userProfile: {},
    isSigningIn: false
};

const fn = (state = INIT_STATE, action) => {
	switch (action.type) {
        case SIGNED_IN:
            return { ...state, isSigningIn:true, isCheckingAuth: true, isAuth: false, hasCheckedAuth: false };
        case SIGNED_IN_SUCCESS:
            return { ...state, isSigningIn: false, isCheckingAuth:false, isAuth: true, authUser:action.payload.authUser, userProfile: action.payload.userProfile,hasCheckedAuth:true };
        case SIGNED_OUT:
            return { ...state, isCheckingAuth:false, isAuth: false, authUser:null, hasCheckedAuth:true, userProfile: {} };
        case IS_AUTHENTICATED:
            return { ...state, isCheckingAuth:true, isAuth: false, authUser:null, hasCheckedAuth:false };
        case IS_AUTHENTICATED_RESULT:
            return { ...state, isCheckingAuth:false, isAuth: action.payload.isAuth, authUser: action.payload.authUser, hasCheckedAuth:true, userProfile: action.payload.userProfile };
        default: 
            return { ...state };
    }
}

export default fn;