import React, { useState, useEffect, useRef } from 'react'
import NavbarMenu from '../layout/navbar/NavbarMenu'
import backgroundImage from "../assets/images/poster.png"
import backgroundVideo from "../assets/images/home.mp4"

function HomeNavbar() {

    const [fadeIn, setFadeIn] = useState(false);
    const [subFadeIn, setSubFadeIn] = useState(false);
    const [navbarMode, setNavbarMode] = useState("full");
    const isMountedRef = useRef(null);

    const changeNavbarClass = () =>{
        if(window.scrollY >= 40){
            setNavbarMode("small");
        }
        else{
            setNavbarMode("full");
        }
     };
     window.addEventListener('scroll', changeNavbarClass);

    useEffect(() => {
        if(isMountedRef){
            setTimeout(function(){ 
                if(isMountedRef) setFadeIn(true) 
            }, 100)
            setTimeout(function(){ 
                if(isMountedRef) setSubFadeIn(true) 
            }, 1000)
        }
    }, []);

    
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return (
        <>
            <nav className={navbarMode + " navbar home col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row"}>
                <NavbarMenu />
            </nav>
            {/* style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }} rgb(33 57 78 / 90%);*/}
            <div className={navbarMode + " home-navhead"} >
                <div id="posterfallback" className="poster" style={{ backgroundColor: "#21394e", zIndex: -2, position: "absolute", top:0, width:"100%", objectFit: "cover" }}>
                </div>
                <div id="posterhome" className="poster" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', zIndex: -1, position: "absolute", top:0, width:"100%", objectFit: "cover" }}>
                </div>
                <video id="videohome" className="poster" poster={backgroundImage} autoPlay muted loop style={{zIndex: -1, position: "absolute", top:0, width:"100%", objectFit: "cover"}}>
                    <source src={backgroundVideo} type="video/mp4" />
                </video>
                {/* "https://amplify-amplifyae11768433f34-staging-155725-deployment.s3-ap-southeast-1.amazonaws.com/home.mp4" */}
                {/* <div className="headline-container w-100 text-center pt-5">
                    <h1 className={fadeIn? "headline show" : "headline" }>Having <span className="highlight">headaches</span> deploying your ML models?</h1>
                    <h3 className={subFadeIn? "subheadline show mt-5": "subheadline mt-5" }>Take a break, Automate, Iterate</h3>
                </div> */}
                <div className="headline-container pt-5">
                    <h1 className={fadeIn? "headline show mb-0" : "headline" }><b>Cloud, Data, AI</b></h1>
                    <h2 className={subFadeIn? "subheadline show": "subheadline mt-5" }>Automate, Take a <span className="highlight">break</span>, Iterate</h2>
                </div>
            </div>
            
           
        </>
    )
}

export default HomeNavbar;