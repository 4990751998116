import { all } from 'redux-saga/effects';

import settingsSagas from './settings/saga';
import authSagas from './auth/saga';
import articleSagas from './article/saga';
import bloggerSagas from './blogger/saga';
import serieSagas from './serie/saga';

export default function* rootSaga(getState) {
  yield all([
    settingsSagas(),
    authSagas(),
    articleSagas(),
    bloggerSagas(),
    serieSagas()
  ]);
}
