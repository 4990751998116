import React, { useState, useEffect } from 'react';
import './App.scss';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
import HomeNavbar from './home/HomeNavbar';
import { useHistory } from 'react-router-dom'
import AppRoutes from './AppRoutes';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { 
  isAuthenticated,
} from "./redux/actions";

function App() {

  const history = useHistory()
  const [homeNavBar, setHomeNavBar] = useState(history.location.pathname === "/");
  const [fullscreen, setFullscreen] = useState(history.location.pathname === "/app/login");
  const [isAppPath, setIsAppPath] = useState(history.location.pathname.startsWith("/app/"));
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch()

  useEffect(() => {
    function handleNavbarType() {
      history.listen((location, _) => {
        console.log("App.js=handleNavbarType", location.pathname)
        if(location.pathname === "/") setHomeNavBar(true)
        else setHomeNavBar(false)
        if(location.pathname === "/app/login") setFullscreen(true)
        else setFullscreen(false)
        if(location.pathname.startsWith("/app/")) setIsAppPath(true)
        else setIsAppPath(false)
      });
    }
    handleNavbarType();
    
    function checkAuth() {
      if(!auth.hasCheckedAuth && !auth.isCheckingAuth) {
        dispatch(isAuthenticated())
      }
    }
    checkAuth();


  }, [history, auth, dispatch]);

  return (
    <>
      {(!fullscreen && (!isAppPath || auth.isAuth)) && (
        <div className="container-scroller">
          { !homeNavBar && <Navbar/> }
          { homeNavBar && <HomeNavbar/> }
          
          <div className="container-fluid page-body-wrapper">
          <div className={homeNavBar? "full main-panel main-panel-home" : "main-panel"}>
              <div className="content-wrapper">
                <AppRoutes/>
              </div>
          </div>
          </div>
          <Footer/>
        </div>
      )}
      {(fullscreen || (isAppPath && !auth.isAuth)) && (
        <div className="fullscreen-bg">
          <AppRoutes/>
        </div>
      )}
    </>
    
  );
}
export default withRouter(App);