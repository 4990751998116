import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { FETCH_BLOGGER } from '../actions';
import {
    fetchBloggerResult
} from './actions';
import { API } from 'aws-amplify';
import { getUser } from '../../graphql/queries';

const fetchBloggerAsync = async (blogger) =>
    await API.graphql({ query: getUser, variables: { userId: blogger }, authMode: 'API_KEY' });


function* fetchBlogger({ payload }) {
    const { bloggerId } = payload
    try {
        const { data } =  yield call(fetchBloggerAsync, bloggerId);
        console.log(data)
        yield put(fetchBloggerResult(data.getUser));
    } catch (err) {
        console.log(err)
        yield put(fetchBloggerResult({}));
    }
}

export function* watchFetchBlogger() {
    yield takeEvery(FETCH_BLOGGER, fetchBlogger);
}

export default function* bloggerSagas() {
    yield all([
        fork(watchFetchBlogger),
    ]);
}