import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    PUBLISH_ARTICLE,
    LIST_ARTICLE_BY_AUTHOR,
    UPDATE_ARTICLE
} from '../actions';
import {
    publishArticleSuccess,
    publishArticleError,
    listArticlesByAuthorResult
} from './actions';
import { API } from 'aws-amplify';
import { 
    createArticle, 
    updateArticle as updateArticleAPI
} from '../../graphql/mutations';
import { articlesByAuthor } from '../../graphql/queries';

const createArticleAsync = async (article) =>
    await API.graphql({ query: createArticle, variables: { input: article } });

const updateArticleAsync = async (article) =>
    await API.graphql({ query: updateArticleAPI, variables: { input: article } });


const listArticleByAuthorAsync = async (user) =>
    await API.graphql({ query: articlesByAuthor, variables: { authorId: user } });



function* publishArticle({ payload }) {
    const { article, history } = payload
    try {
        const publishedArticle =  yield call(createArticleAsync, article);
        console.log('Saga=article/publishArticle', publishedArticle)
        const articles = yield call(listArticleByAuthorAsync, article.authorId);
        console.log('Saga=article/publishArticle', articles)
        yield put(publishArticleSuccess(articles.data.articlesByAuthor));
        history.push("/app/editor")

    } catch (err) {
        console.log(err)
        yield put(publishArticleError());
    }
}

function* updateArticle({ payload }) {
    const { article, history } = payload
    try {
        const publishedArticle =  yield call(updateArticleAsync, article);
        console.log('Saga=article/updateArticle', publishedArticle)
        const articles = yield call(listArticleByAuthorAsync, article.authorId);
        console.log('Saga=article/updateArticle', articles)
        yield put(publishArticleSuccess(articles.data.articlesByAuthor));
        history.push("/app/editor")
    } catch (err) {
        console.log(err)
        yield put(publishArticleError());
    }
}
// function* listArticles({ payload }) {
//     const { authorId } = payload
//     try {
//         const result = yield call(listArticleByAuthorAsync, authorId);
//         console.log(result)
//         return result.data.itemsByAuthor
//     } catch (err) {
//         return { items: [], nextToken: null }
//     }
// }

function* listArticlesByAuthor({ payload }) {
    const { authorId } = payload
    try {
        const { data } = yield call(listArticleByAuthorAsync, authorId);
        console.log('Saga=article/listArticlesByAuthor', data)
        yield put(listArticlesByAuthorResult(data.articlesByAuthor));
    } catch (err) {
        yield put(listArticlesByAuthorResult({ items: [], nextToken: null }));
    }
}



export function* watchPublishArticle() {
    yield takeEvery(PUBLISH_ARTICLE, publishArticle);
}

export function* watchUpdateArticle() {
    yield takeEvery(UPDATE_ARTICLE, updateArticle);
}

export function* watchListArticlesByAuthor() {
    yield takeEvery(LIST_ARTICLE_BY_AUTHOR, listArticlesByAuthor);
}

export default function* articleSagas() {
    yield all([
        fork(watchPublishArticle),
        fork(watchUpdateArticle),
        fork(watchListArticlesByAuthor)
    ]);
}