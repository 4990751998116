import {
    FETCH_BLOGGER,
    FETCH_BLOGGER_SUCCESS
} from '../actions';

export const fetchBlogger = ( bloggerId ) => ({
    type: FETCH_BLOGGER,
    payload: { bloggerId }
});

export const fetchBloggerResult = (blogger) => ({
    type: FETCH_BLOGGER_SUCCESS,
    payload: { blogger }
});