import React from 'react'

function Spinner(props) {

    return (
      <div>
        <div className="spinner-wrapper" style={{position: 'initial', width: "100%" }}>
          <div className="donut"></div>
        </div>
      </div>
    )
}

export default Spinner;