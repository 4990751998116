import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    IS_AUTHENTICATED,
    SIGNED_IN,
    SIGNED_UP
} from '../actions';
import {
    isAuthenticatedResult,
    signedInSuccess
} from './actions';
import Auth from "@aws-amplify/auth";
import { API } from 'aws-amplify';
import { createUser } from '../../graphql/mutations';
import { getUser } from '../../graphql/queries';


const currentSessionAsync = async () =>
    await await Auth.currentSession()

const currentUserCredentialsAsync = async () =>
    await await Auth.currentUserCredentials()

const createUserAsync = async (user) =>
    await API.graphql({ query: createUser, variables: { input: user } });

const getUserAsync = async (userId) =>
    await API.graphql({ query: getUser, variables: { userId: userId } });

// const currentAuthenticatedUserAsync = async () =>
//     await Auth.currentAuthenticatedUser()

function* isAuthenticated() {
    try {
        let authUser =  yield call(currentSessionAsync);
        let userProfile = null;
        if(authUser.isValid()) {
            userProfile = yield call(getUserAsync, authUser.idToken.payload.sub);
            userProfile = userProfile.data.getUser
        }
        let authCreds = yield call(currentUserCredentialsAsync)
        userProfile.identityId = authCreds.identityId
        yield put(isAuthenticatedResult(authUser.isValid(), authUser, userProfile));
    } catch (err) {
        yield put(isAuthenticatedResult(false, null));
    }
}

function* getOrCreateUser({ payload }) {
    try {
        const { authUser, history } = payload
        const authData = authUser.idToken ? authUser.idToken.payload : authUser.attributes
        const { data } = yield call(getUserAsync, authData.sub);
        let output = {}
        console.log()
        if(!data.getUser) {
            const user = {
                userId: authData.sub,
                firstName: authData.name,
                lastName: authData.family_name,
                emailAddress: authData.email,
                isActive: true
            }
            const result = yield call(createUserAsync, user);
            output = result.data.createUser
        } else {
            output = data.getUser
        }
        if(authUser.attributes) {
            let authCreds = yield call(currentUserCredentialsAsync)
            output.identityId = authCreds.identityId
            yield put(signedInSuccess(authUser, output))
            history.push("/app/home")
        } else return output
    } catch (err) {
        console.log('[ERR] - AuthSaga=getOrCreateUser', err)
    }
}


export function* watchHasSignedIn() {
    yield takeEvery(SIGNED_IN, getOrCreateUser);
}
export function* watchIsAuthenticated() {
    yield takeEvery(IS_AUTHENTICATED, isAuthenticated);
}

export function* watchHasSignedUp() {
    yield takeEvery(SIGNED_UP, getOrCreateUser);
}


export default function* authSagas() {
    yield all([
        fork(watchIsAuthenticated),
        fork(watchHasSignedUp),
        fork(watchHasSignedIn)
    ]);
}