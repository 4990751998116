

const INIT_STATE = {
    isExample: true,
    name: 'jello'
};


const fn = (state = INIT_STATE, action) => {
	switch (action.type) {
        default: 
            return { ...state };
    }
}

export default fn;