import {
    LIST_SERIES_BY_AUTHOR,
    LIST_SERIES_BY_AUTHOR_RESULT
} from '../actions';

export const listSeriesByAuthor = (authorId) => ({
    type: LIST_SERIES_BY_AUTHOR,
    payload: {authorId}
});

export const listSeriesByAuthorResult = (series) => ({
    type: LIST_SERIES_BY_AUTHOR_RESULT,
    payload: { series }
});
