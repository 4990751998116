import React from 'react'
import NavbarMenu from './navbar/NavbarMenu'

function Navbar() {

    return (
        <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
            <NavbarMenu />
        </nav>
    )
}

export default Navbar;