import {
    IS_AUTHENTICATED,
    IS_AUTHENTICATED_RESULT,
    SIGNED_IN,
    SIGNED_IN_SUCCESS,
    SIGNED_UP,
    SIGNED_OUT
} from '../actions';

export const isAuthenticated = () => ({
    type: IS_AUTHENTICATED,
    payload: {}
});

export const isAuthenticatedResult = (isAuth, authUser, userProfile) => ({
    type: IS_AUTHENTICATED_RESULT,
    payload: { isAuth, authUser, userProfile }
});

export const signedIn = (authUser, history) => ({
    type: SIGNED_IN,
    payload: {authUser , history}
});

export const signedInSuccess = (authUser, userProfile) => ({
    type: SIGNED_IN_SUCCESS,
    payload: {authUser, userProfile}
});

export const signedOut = () => ({
    type: SIGNED_OUT
});

export const signedUp = (authUser) => ({
    type: SIGNED_UP,
    payload: {authUser}
});