import React from 'react'
import freeldomIcon from "../../assets/images/freeldom.png"
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

function NavbarMenu() {

    const auth = useSelector(state => state.auth);

    return (
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
            <ul className="navbar-nav navbar-nav-left header-links align-self-center">
                <li className="nav-item font-weight-semibold  d-md-flex">
                    <Link to="/"><img src={freeldomIcon} alt="icon" className="navbar-logo"></img></Link>
                </li>
            </ul>
            <ul className="navbar-nav navbar-nav-right">
                <li className="nav-item  nav-profile border-0 pl-4">
                    <Link to="/" className="nav-link">Home</Link>
                </li>
                <li className="nav-item pl-4 nav-profile border-0">
                    <Link to="/about" className="nav-link">About</Link>
                </li>
                <li className="nav-item pl-4 nav-profile border-0">
                    <Link to={auth.isAuth ? '/app/home' : '/app/login' } className="nav-link">{auth.isAuth ? 'App' : 'Login' }</Link>
                </li>
            </ul>
        </div>
    )
}

export default NavbarMenu;