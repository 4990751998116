
/* Example */
export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const IS_AUTHENTICATED_RESULT = "IS_AUTHENTICATED_RESULT";
export const SIGNED_IN = "SIGNED_IN";
export const SIGNED_IN_SUCCESS = "SIGNED_IN_SUCCESS";
export const SIGNED_OUT = "SIGNED_OUT";
export const SIGNED_UP = "SIGNED_UP";

/* ARTICLE */
export const PUBLISH_ARTICLE = "PUBLISH_ARTICLE";
export const PUBLISH_ARTICLE_SUCCESS = "PUBLISH_ARTICLE_SUCCESS";
export const PUBLISH_ARTICLE_ERROR = "PUBLISH_ARTICLE_ERROR";
export const LIST_ARTICLE_BY_AUTHOR = "LIST_ARTICLE_BY_AUTHOR";
export const LIST_ARTICLE_BY_AUTHOR_RESULT = "LIST_ARTICLE_BY_AUTHOR_RESULT";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";

/* BLOGGER */
export const FETCH_BLOGGER = "FETCH_BLOGGER";
export const FETCH_BLOGGER_SUCCESS = "FETCH_BLOGGER_SUCCESS";

/* SERIES */
export const LIST_SERIES_BY_AUTHOR = "LIST_SERIES_BY_AUTHOR";
export const LIST_SERIES_BY_AUTHOR_RESULT = "LIST_SERIES_BY_AUTHOR_RESULT";



export * from "./auth/actions";
export * from "./blogger/actions";
export * from "./settings/actions";
export * from "./article/actions";
export * from "./serie/actions";