/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      userId
      firstName
      lastName
      emailAddress
      biography
      interests
      technologies
      socialLinks {
        github
        linkedin
        website
      }
      profilePicture {
        bucket
        region
        key
        level
        identity_pool_id
      }
      isActive
      inactiveDate
      createdAt
      updatedAt
      series {
        items {
          serieId
          title
          authorId
          description
          articleOrder
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      articles {
        items {
          articleId
          createdTimestamp
          authorId
          title
          tag
          refId
          serieId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      userId
      firstName
      lastName
      emailAddress
      biography
      interests
      technologies
      socialLinks {
        github
        linkedin
        website
      }
      profilePicture {
        bucket
        region
        key
        level
        identity_pool_id
      }
      isActive
      inactiveDate
      createdAt
      updatedAt
      series {
        items {
          serieId
          title
          authorId
          description
          articleOrder
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      articles {
        items {
          articleId
          createdTimestamp
          authorId
          title
          tag
          refId
          serieId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      userId
      firstName
      lastName
      emailAddress
      biography
      interests
      technologies
      socialLinks {
        github
        linkedin
        website
      }
      profilePicture {
        bucket
        region
        key
        level
        identity_pool_id
      }
      isActive
      inactiveDate
      createdAt
      updatedAt
      series {
        items {
          serieId
          title
          authorId
          description
          articleOrder
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      articles {
        items {
          articleId
          createdTimestamp
          authorId
          title
          tag
          refId
          serieId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createBlogSerie = /* GraphQL */ `
  mutation CreateBlogSerie(
    $input: CreateBlogSerieInput!
    $condition: ModelBlogSerieConditionInput
  ) {
    createBlogSerie(input: $input, condition: $condition) {
      serieId
      title
      authorId
      description
      articleOrder
      createdAt
      updatedAt
      author {
        userId
        firstName
        lastName
        emailAddress
        biography
        interests
        technologies
        socialLinks {
          github
          linkedin
          website
        }
        profilePicture {
          bucket
          region
          key
          level
          identity_pool_id
        }
        isActive
        inactiveDate
        createdAt
        updatedAt
        series {
          nextToken
        }
        articles {
          nextToken
        }
      }
      owner
      articles {
        items {
          articleId
          createdTimestamp
          authorId
          title
          tag
          refId
          serieId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateBlogSerie = /* GraphQL */ `
  mutation UpdateBlogSerie(
    $input: UpdateBlogSerieInput!
    $condition: ModelBlogSerieConditionInput
  ) {
    updateBlogSerie(input: $input, condition: $condition) {
      serieId
      title
      authorId
      description
      articleOrder
      createdAt
      updatedAt
      author {
        userId
        firstName
        lastName
        emailAddress
        biography
        interests
        technologies
        socialLinks {
          github
          linkedin
          website
        }
        profilePicture {
          bucket
          region
          key
          level
          identity_pool_id
        }
        isActive
        inactiveDate
        createdAt
        updatedAt
        series {
          nextToken
        }
        articles {
          nextToken
        }
      }
      owner
      articles {
        items {
          articleId
          createdTimestamp
          authorId
          title
          tag
          refId
          serieId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteBlogSerie = /* GraphQL */ `
  mutation DeleteBlogSerie(
    $input: DeleteBlogSerieInput!
    $condition: ModelBlogSerieConditionInput
  ) {
    deleteBlogSerie(input: $input, condition: $condition) {
      serieId
      title
      authorId
      description
      articleOrder
      createdAt
      updatedAt
      author {
        userId
        firstName
        lastName
        emailAddress
        biography
        interests
        technologies
        socialLinks {
          github
          linkedin
          website
        }
        profilePicture {
          bucket
          region
          key
          level
          identity_pool_id
        }
        isActive
        inactiveDate
        createdAt
        updatedAt
        series {
          nextToken
        }
        articles {
          nextToken
        }
      }
      owner
      articles {
        items {
          articleId
          createdTimestamp
          authorId
          title
          tag
          refId
          serieId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createArticle = /* GraphQL */ `
  mutation CreateArticle(
    $input: CreateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    createArticle(input: $input, condition: $condition) {
      articleId
      createdTimestamp
      authorId
      title
      podcast {
        bucket
        region
        key
        level
        identity_pool_id
      }
      tag
      thumbnail {
        bucket
        region
        key
        level
        identity_pool_id
      }
      body {
        bucket
        region
        key
        level
        identity_pool_id
      }
      refId
      serieId
      createdAt
      updatedAt
      author {
        userId
        firstName
        lastName
        emailAddress
        biography
        interests
        technologies
        socialLinks {
          github
          linkedin
          website
        }
        profilePicture {
          bucket
          region
          key
          level
          identity_pool_id
        }
        isActive
        inactiveDate
        createdAt
        updatedAt
        series {
          nextToken
        }
        articles {
          nextToken
        }
      }
      serie {
        serieId
        title
        authorId
        description
        articleOrder
        createdAt
        updatedAt
        author {
          userId
          firstName
          lastName
          emailAddress
          biography
          interests
          technologies
          isActive
          inactiveDate
          createdAt
          updatedAt
        }
        owner
        articles {
          nextToken
        }
      }
      owner
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle(
    $input: UpdateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    updateArticle(input: $input, condition: $condition) {
      articleId
      createdTimestamp
      authorId
      title
      podcast {
        bucket
        region
        key
        level
        identity_pool_id
      }
      tag
      thumbnail {
        bucket
        region
        key
        level
        identity_pool_id
      }
      body {
        bucket
        region
        key
        level
        identity_pool_id
      }
      refId
      serieId
      githubResources {
        name
        link
      }
      externalResources {
        name
        link
      }
      createdAt
      updatedAt
      author {
        userId
        firstName
        lastName
        emailAddress
        biography
        interests
        technologies
        socialLinks {
          github
          linkedin
          website
        }
        profilePicture {
          bucket
          region
          key
          level
          identity_pool_id
        }
        isActive
        inactiveDate
        createdAt
        updatedAt
        series {
          nextToken
        }
        articles {
          nextToken
        }
      }
      serie {
        serieId
        title
        authorId
        description
        articleOrder
        createdAt
        updatedAt
        author {
          userId
          firstName
          lastName
          emailAddress
          biography
          interests
          technologies
          isActive
          inactiveDate
          createdAt
          updatedAt
        }
        owner
        articles {
          nextToken
        }
      }
      owner
    }
  }
`;
export const deleteArticle = /* GraphQL */ `
  mutation DeleteArticle(
    $input: DeleteArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    deleteArticle(input: $input, condition: $condition) {
      articleId
      createdTimestamp
      authorId
      title
      podcast {
        bucket
        region
        key
        level
        identity_pool_id
      }
      tag
      thumbnail {
        bucket
        region
        key
        level
        identity_pool_id
      }
      body {
        bucket
        region
        key
        level
        identity_pool_id
      }
      refId
      serieId
      createdAt
      updatedAt
      author {
        userId
        firstName
        lastName
        emailAddress
        biography
        interests
        technologies
        socialLinks {
          github
          linkedin
          website
        }
        profilePicture {
          bucket
          region
          key
          level
          identity_pool_id
        }
        isActive
        inactiveDate
        createdAt
        updatedAt
        series {
          nextToken
        }
        articles {
          nextToken
        }
      }
      serie {
        serieId
        title
        authorId
        description
        articleOrder
        createdAt
        updatedAt
        author {
          userId
          firstName
          lastName
          emailAddress
          biography
          interests
          technologies
          isActive
          inactiveDate
          createdAt
          updatedAt
        }
        owner
        articles {
          nextToken
        }
      }
      owner
    }
  }
`;
