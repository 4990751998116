import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    LIST_SERIES_BY_AUTHOR
} from '../actions';
import {
    listSeriesByAuthorResult
} from './actions';
import { API } from 'aws-amplify';
import { seriesByAuthor } from '../../graphql/queries';


const listSeriesByAuthorAsync = async (user) =>
    await API.graphql({ query: seriesByAuthor, variables: { authorId: user } });

function* listSeriesByAuthor({ payload }) {
    const { authorId } = payload
    try {
        const { data } = yield call(listSeriesByAuthorAsync, authorId);
        console.log('Saga=serie/listSeriesByAuthor', data)
        const serieList = []
        for(var i=0; i<data.seriesByAuthor.items.length; i++) {
            if(data.seriesByAuthor.items[i].serieId !== '0') serieList.push(data.seriesByAuthor.items[i])
        }
        yield put(listSeriesByAuthorResult({ items: serieList, nextToken: data.seriesByAuthor.nextToken }));
    } catch (err) {
        yield put(listSeriesByAuthorResult({ items: [], nextToken: null }));
    }
}

export function* watchListSeriesByAuthor() {
    yield takeEvery(LIST_SERIES_BY_AUTHOR, listSeriesByAuthor);
}

export default function* serieSagas() {
    yield all([
        fork(watchListSeriesByAuthor)
    ]);
}