import {
    PUBLISH_ARTICLE,
    PUBLISH_ARTICLE_SUCCESS,
    PUBLISH_ARTICLE_ERROR,
    LIST_ARTICLE_BY_AUTHOR,
    LIST_ARTICLE_BY_AUTHOR_RESULT,
    UPDATE_ARTICLE
} from '../actions';

export const publishArticle = (article, history) => ({
    type: PUBLISH_ARTICLE,
    payload: {article, history}
});

export const updateArticle = (article, history) => ({
    type: UPDATE_ARTICLE,
    payload: {article, history}
});


export const publishArticleSuccess = (articles) => ({
    type: PUBLISH_ARTICLE_SUCCESS,
    payload: { articles }
});

export const publishArticleError = () => ({
    type: PUBLISH_ARTICLE_ERROR
});


export const listArticlesByAuthor = (authorId) => ({
    type: LIST_ARTICLE_BY_AUTHOR,
    payload: {authorId}
});

export const listArticlesByAuthorResult = (articles) => ({
    type: LIST_ARTICLE_BY_AUTHOR_RESULT,
    payload: { articles }
});



